import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';

import BarChart from '../../../components/tradingviewstockinformation';
import BreadcrumbMenu from '../../../components/menus/bcmenu';
import Layout from '../../../components/layout';
import React from 'react';
import SEO from '../../../components/seo';
import SideNavBar from '../../../components/menus/navbarmenu';
import { graphql } from 'gatsby';

let gmdv = require(`../../../ghc_config`);

const Detail = ({ data, location }) => {

  //  Menu relation
  let menuName = gmdv.getMenuid(location)

  //  Page
  const title = 'Stock Information'
  //const barchartValue = `https://www.barchart.com/widget.js?uid=16d70ed26994396157850ea7c63b7b54&widgetType=chart&showChart=true&widgetWidth=600&chartType=AREA&chartColor%5Barea%5D=EE1C25&fontColor%5Blinks%5D=EE1C25&font=1&symbols%5B%5D=GENN&symbols%5B%5D=&symbols%5B%5D=&symbols%5B%5D=&symbols%5B%5D=&fields%5B%5D=name&fields%5B%5D=last&fields%5B%5D=change&fields%5B%5D=pctchange&displayChars=`

  let backgroundImage = ''
  if (data.nodeBoardOfDirectors.relationships.field_image_banner != null) {
    backgroundImage = data.nodeBoardOfDirectors.relationships.field_image_banner.localFile.childImageSharp.boardofdirectors.src
    backgroundImage = encodeURI(backgroundImage)
  }

  return (
    <Layout>
      <SEO 
        title={title}
        description={`Genesis HealthCare offers long term care services, short stay care, transitional care services, rehab services and specialty services`}
        keywords={[`Long Term Care Services`, `Transitional Care`, `Transitional Care Services`, `Genesis Rehab`]} 
      />
      <main>
        <MDBContainer tag="section" id="maincontent">
          <MDBRow center>
            <MDBCol xs="12" md="3" lg="3" className="col-12 leftside">
              <SideNavBar menuName={menuName} location={location}/>
            </MDBCol>
            <MDBCol xs="12" md="9" lg="9" className="col-12 contentRight">
              <h1 className="pagetitle" 
                style={{backgroundImage: `url(${backgroundImage})`}}>
                {title}
              </h1>
              <BreadcrumbMenu menuName={menuName} location={location}/>
              <MDBRow center>
                <MDBCol xs="12" md="12" lg="12" className="col-12 px-3">
                  <BarChart title={title}/>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </main>
    </Layout>
  )
}

export default Detail;

export const query = graphql`
{
  nodeBoardOfDirectors(id: {eq: "b1e091d6-aabf-508c-8f7b-8a4b187871bc"}) {
    relationships {
      field_image_banner {
        localFile {
          childImageSharp {
            boardofdirectors: original {
              src
            }
          }
        }
      }
    }
  }

  # Menu relation
  allMenuLinkContentMenuLinkContent {
    edges {
      node {
        title
        link {
          uri
        }
        menu_name
      }
    }
  }
}
`