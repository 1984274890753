import React from 'react';

const Barchart = (props) => (
  <iframe 
    title={props.title}
    width="100%"
    height="410"
    frameBorder="0"
    srcDoc={`<!-- TradingView Widget BEGIN -->
    <div class="tradingview-widget-container">
      <div id="tradingview_ba7a9"></div>
      <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/symbols/OTC-GENN/" rel="noopener" target="_blank"><span class="blue-text">GENN Stock Price Today</span></a> by TradingView</div>
      <script type="text/javascript" src="https://s3.tradingview.com/tv.js"></script>
      <script type="text/javascript">
      new TradingView.MediumWidget(
      {
      "symbols": [
        [
          "OTC:GENN|3M"
        ]
      ],
      "chartOnly": false,
      "width": "100%",
      "height": "390",
      "locale": "en",
      "colorTheme": "light",
      "gridLineColor": "rgba(182, 182, 182, 1)",
      "trendLineColor": "rgba(182, 182, 182, 1)",
      "fontColor": "rgba(66, 66, 66, 1)",
      "underLineColor": "rgba(238, 28, 37, 1)",
      "isTransparent": true,
      "autosize": true,
      "container_id": "tradingview_ba7a9"
    }
      );
      </script>
    </div>
    <!-- TradingView Widget END -->`}>
    Browser not compatible.
  </iframe>
)

export default Barchart;